
import React from 'react';
import "./Faq.css";
import faqline from "../../Assets/faqline.png"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

function Faq() {
 
  return (
    <div className='main_faq'>
    <div className='container'>
    <div className='row'>
   <div className='col-xl-6 col-lg-'>
   <h1 className='faq_head'>Frequently Asked Questions</h1>
   <img src={faqline} alt='faq'/>
   <p className='faq_ques'>Have Questions In Your Mind?
   Get <span className='faq_queso'>  The Answers Now</span></p>
   </div>
   <div class="col-xl-6 col-lg- accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
     <strong className='faq_contenthead'>Interdum et malesuada fames ac ante ipsum</strong> 
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
       Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget
        egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante. Suspendisse
        sit amet neque euismod, convallis quam eget, dignissim massa. Aliquam blandit risus
        purus, in congue nunc venenatis id.
      
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      <strong className='faq_contenthead'>Maecenas condimentum sollicitudin ligula,</strong> 
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget
      egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante. Suspendisse
      sit amet neque euismod, convallis quam eget, dignissim massa. Aliquam blandit risus
      purus, in congue nunc venenatis id.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      <strong className='faq_contenthead'>Maecenas condimentum sollicitudin ligula,</strong>
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      Proin efficitur, mauris vel condimentum pulvinar, velit orci consectetur ligula, eget
      egestas magna mi ut arcu. Phasellus nec odio orci. Nunc id massa ante. Suspendisse
      sit amet neque euismod, convallis quam eget, dignissim massa. Aliquam blandit risus
      purus, in congue nunc venenatis id.
      </div>
    </div>
  </div>
</div>
  </div>
  </div>
    </div>
  )
}

export default Faq
