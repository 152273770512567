import React from 'react'
import "../NavbarMain/Navbar.css";
import stopshop_logo from "../../Assets/stopshop_logo.png";
import arrownav from "../../Assets/arrownav.png"
function Navbar() {
  return (
    <div>
<div className='gen_sol'>
<div className='row m-0 p-0 navbarcontent_row'>
<div className='col-xl-1 col-lg-0'></div>
<div className='col-xl-8 col-lg-12 col-md-12 col-12'>
<p className='navbar_main_head'>Complete Real Estate Lead</p>
<p className='navbar_main_subhead'>Generation <span className='navbar_main_subheadcolor'>Solutions</span></p>
<p className='navbar_main_content'>StopShopREI is Your Premier Digital Marketing and Virtual <span class="visible-br"><br/></span><span>Assistance Hub</span> 
</p>
<div className='mt-3'>
<button className='discover_btn'>DISCOVER MORE</button> &nbsp;
<button className='callnow_btn'>Call now &nbsp;<img src={arrownav} alt='arrownav'/></button></div>
</div>
<div className='col-xl-2'></div>
</div>
</div>                       
    </div>
  )
}

export default Navbar
