import React, { useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "./Testimonial.css"
import feedback from "../../Assets/client_feedback.png"
const Testimonial = () => {


  return (
    <div className='main_testimonial'>
      <div className="container gtco-testimonials">
        <h2 className='client_head'>OUR CLIENT FEEDBACK</h2>
        <p className='client_subhead'>Intrinsicly transition intermandated content through accurate manufactured
        products. Uniquely exploit premier best.</p>
        <OwlCarousel
          className="owl-carousel owl-theme"
          loop
          center
          margin={0}
          responsiveClass
          nav={false}
          responsive={{
            0: { items: 1, nav: false },
            680: { items: 2, nav: false, loop: false },
            1000: { items: 3, nav: true }
          }}
        >
        <div>
        <div class="card text-center"><img class="card-img-top" src={feedback} alt="feedback"/>
          <div class="card-body">
            <h5 className='feedback_name'>Ronne Galle <br />
              <span className='feedback_desig'> Project Manager </span>
            </h5>
            <p class="card-text feedback_content">“ Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil
              impedit quo minus id quod maxime placeat ” </p>
          </div>
        </div>
      </div>
      <div>
      <div class="card text-center"><img class="card-img-top" src={feedback} alt="feedback"/>
        <div class="card-body">
          <h5 className='feedback_name'>Ronne Galle <br />
            <span className='feedback_desig'> Project Manager </span>
          </h5>
          <p class="card-text feedback_content">“ Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil
            impedit quo minus id quod maxime placeat ” </p>
        </div>
      </div>
    </div>
      <div>
        <div class="card text-center"><img class="card-img-top" src={feedback} alt="feedback"/>
          <div class="card-body">
            <h5 className='feedback_name'>Martha Brown<br />
              <span className='feedback_desig'> Project Manager </span>
            </h5>
            <p class="card-text feedback_content">“ Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil
              impedit quo minus id quod maxime placeat ” </p>
          </div>
        </div>
      </div>
     <div>
        <div class="card text-center"><img class="card-img-top" src={feedback} alt="feedback"/>
          <div class="card-body">
            <h5 className='feedback_name'>Hanna Lisem<br />
              <span className='feedback_desig'> Project Manager </span>
            </h5>
            <p class="card-text feedback_content">“ Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil
              impedit quo minus id quod maxime placeat ” </p>
          </div>
        </div>
        </div>
        </OwlCarousel>
      </div>
    </div>
  );
};

export default Testimonial;
