import React from 'react'
import "./Footer.css"
import blog1 from "../../Assets/footer_blog.png"
import blog from "../../Assets/blog_footer2.png"
import phone from "../../Assets/footer_phone.png"
import email from "../../Assets/footer_email.png"
import address from "../../Assets/footer_address.png"
import arrow from "../../Assets/link_arrow.png"
import phone_circle from "../../Assets/phone_circle.png"
function Footer() {
  return (
    <div className='main_footer'>
     


  <footer class="footer">
  	 <div class="container">
     <div className='upper_footer'>
     <div class="d-flex flex-row ">
  <div class="col-xl-5 col-lg-6 upper_main">Get your Free
  Business
  Consolution</div>
  <div class="col-xl-2 col-lg-2"><img className='phone_circle' src={phone_circle} alt='phonecircle'/></div>
  <div class="col-xl-4 col-lg-4">
  <p className='uperfotter_phone'>+98 376 (2890) 100</p>
  <p className='uperfotter_phone1'>example@gmail.com</p>
  </div>
</div>
     </div>
  	 	<div class="row">
  	 		<div class="col-xl-3 col-md-6 footer-col">
  	 			<h4 className='footer_heading'>About Company</h4>
  	 		<p className='sub_contact_detail'>Centric aplications productize front
         end vortals visualize front end isite
         results and value added globally for
         simplify alternative systems without
         cross-platform models.</p>
         <div class="social-links">
  	 				<a href="#"><i class="fab fa-facebook-f"></i></a>
  	 				<a href="#"><i class="fab fa-twitter"></i></a>
  	 				<a href="#"><i class="fab fa-instagram"></i></a>
  	 				<a href="#"><i class="fab fa-linkedin-in"></i></a>
  	 			</div>
  	 		</div>
  	 		<div class="col-xl-3 col-md-6 footer-col">
  	 			<h4 className='footer_heading'>Quick link</h4>
  	 			<ul className='ql'>
  	 				<li><a href="#"><span><img className='pr-2 ql_links' src={arrow} alt='arrow'/></span>About Us</a></li>
  	 				<li><a href="#"><span><img className='pr-2 ql_links' src={arrow} alt='arrow'/></span>Book Ride</a></li>
  	 				<li><a href="#"><span><img className='pr-2 ql_links' src={arrow} alt='arrow'/></span>Client Feedback</a></li>
  	 				<li><a href="#"><span><img className='pr-2 ql_links' src={arrow} alt='arrow'/></span>Our Services</a></li>
  	 				<li><a href="#"><span><img className='pr-2 ql_links' src={arrow} alt='arrow'/></span>Our Drivers</a></li>
             <li><a href="#"><span><img className='pr-2 ql_links' src={arrow} alt='arrow'/></span>Contact Us</a></li>
  	 			</ul>
  	 		</div>
  	 		<div class="col-xl-3 col-md-6 footer-col">
  	 			<h4 className='footer_heading'>Recent Posts</h4>
  	 		<img src={blog1} alt='blog1'/>
         <img src={blog} alt='blog'/>
  	 		</div>
  	 		<div class="col-xl-3 col-md-6 footer-col">
  	 			<h4 className='footer_heading'>Contact Details</h4>
  	 			<h2 className='sub_contact'>Phone Number</h2>
           <div class="d-flex flex-row">
           <img class="p-2 contact_img" src={phone} alt='phone'/>
           <div class="p-2 sub_contact_detail">+468 254 762 443</div>
          
         </div>
         <h2 className='sub_contact'>Email Address</h2>
           <div class="d-flex flex-row">
           <img class="p-2 contact_img" src={email} alt='email'/>
           <div class="p-2 sub_contact_detail">info@KKJKaxiar.com</div>
          
         </div>
         <h2 className='sub_contact'>Office Location</h2>
           <div class="d-flex flex-row">
           <img class="p-2 contact_img" src={address} alt='address'/>
           <div class="p-2 sub_contact_detail">25 Street, 145 City Road New
           Town DD14, USA</div>
          
         </div>
  	 		</div>
  	 	</div>
  	 </div>
    
     </footer>
     <div className='footer_last p-3'>© 2023 Taxiar. All Rights Reserved.</div>


    </div>
  )
}

export default Footer
