import React, { useState, useEffect } from 'react';
import "./ServiceTest.css";

import one from "../../Assets/acqmanager.png";
import two from "../../Assets/disposition.png";
import three from "../../Assets/followupmanager.png";
import four from "../../Assets/ringless.png";
import five from "../../Assets/emailmarketing.png";
import six from "../../Assets/postcard.png";
import seven from "../../Assets/textmarketing.png";
import eight from "../../Assets/listbuilding.png";
import nine from "../../Assets/coldcalling.png";
import ten from "../../Assets/account.png";
import eleven from "../../Assets/blogandcontent.png";
import { Link } from 'react-router-dom';

function ServiceTest() {
  const [selectedServiceIndex, setSelectedServiceIndex] = useState(0);
  const [services, setServices] = useState([
    "List Building & Data Management",
    "Cold Calling",
    "Follow up Manager",
    "Acquisition Manager",
    "Dispositions",
    "Email Marketing",
    "Ringless Voice Mails",
    "Post Card Campaigns",
    "Text Marketing",
    "Accounts & Book Keeping",
    "Blog and Content Writing"
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedServiceIndex(prevIndex => (prevIndex + 1) % services.length);
    }, 5000); // Change interval to 2000 milliseconds (2 seconds)

    return () => clearInterval(interval);
  }, [services]);

  const handleServiceClick = (service) => {
    setSelectedServiceIndex(services.indexOf(service));
  };

  const serviceImages = {
    "List Building & Data Management": one,
    "Cold Calling": two,
    "Follow up Manager": three,
    "Acquisition Manager": four,
    "Dispositions": five,
    "Email Marketing": six,
    "Ringless Voice Mails": seven,
    "Post Card Campaigns": eight,
    "Text Marketing": nine,
    "Accounts & Book Keeping": ten,
    "Blog and Content Writing": eleven
  };

  const serviceContent = {
    "List Building & Data Management": <ul><li className='li_items'>The wholesale eco system is holds to major setups Acquistions and Dispostions</li>
    <li className='li_items'>Some investor reached to the point where they got a property under contract and after that they don't have process of disposition of that property and were unable to generate cash flow and sometime loose the deal if it's an assignment contract. </li>
    <li className='li_items'>We have designed a process in which we expidite your real estate investors circle and help to sell your proprty they we helped you acquire the property.</li>
    </ul>,
    "Cold Calling":  <ul><li className='li_items'>The wholesale eco system is holds to major setups Acquistions and Dispostions</li>
    <li className='li_items'>Some investor reached to the point where they got a property under contract and after that they don't have process of disposition of that property and were unable to generate cash flow and sometime loose the deal if it's an assignment contract. </li>
    <li className='li_items'>We have designed a process in which we expidite your real estate investors circle and help to sell your proprty they we helped you acquire the property.</li>
    </ul>,
    "Follow up Manager":  <ul><li className='li_items'>The wholesale eco system is holds to major setups Acquistions and Dispostions</li>
    <li className='li_items'>Some investor reached to the point where they got a property under contract and after that they don't have process of disposition of that property and were unable to generate cash flow and sometime loose the deal if it's an assignment contract. </li>
    <li className='li_items'>We have designed a process in which we expidite your real estate investors circle and help to sell your proprty they we helped you acquire the property.</li>
    </ul>,
    "Acquisition Manager":  <ul><li className='li_items'>The wholesale eco system is holds to major setups Acquistions and Dispostions</li>
    <li className='li_items'>Some investor reached to the point where they got a property under contract and after that they don't have process of disposition of that property and were unable to generate cash flow and sometime loose the deal if it's an assignment contract. </li>
    <li className='li_items'>We have designed a process in which we expidite your real estate investors circle and help to sell your proprty they we helped you acquire the property.</li>
    </ul>,
    "Dispositions":  <ul><li className='li_items'>The wholesale eco system is holds to major setups Acquistions and Dispostions</li>
    <li className='li_items'>Some investor reached to the point where they got a property under contract and after that they don't have process of disposition of that property and were unable to generate cash flow and sometime loose the deal if it's an assignment contract. </li>
    <li className='li_items'>We have designed a process in which we expidite your real estate investors circle and help to sell your proprty they we helped you acquire the property.</li>
    </ul>,
    "Email Marketing":  <ul><li className='li_items'>The wholesale eco system is holds to major setups Acquistions and Dispostions</li>
    <li className='li_items'>Some investor reached to the point where they got a property under contract and after that they don't have process of disposition of that property and were unable to generate cash flow and sometime loose the deal if it's an assignment contract. </li>
    <li className='li_items'>We have designed a process in which we expidite your real estate investors circle and help to sell your proprty they we helped you acquire the property.</li>
    </ul>,
    "Ringless Voice Mails":  <ul><li className='li_items'>The wholesale eco system is holds to major setups Acquistions and Dispostions</li>
    <li className='li_items'>Some investor reached to the point where they got a property under contract and after that they don't have process of disposition of that property and were unable to generate cash flow and sometime loose the deal if it's an assignment contract. </li>
    <li className='li_items'>We have designed a process in which we expidite your real estate investors circle and help to sell your proprty they we helped you acquire the property.</li>
    </ul>,
    "Post Card Campaigns":  <ul><li className='li_items'>The wholesale eco system is holds to major setups Acquistions and Dispostions</li>
    <li className='li_items'>Some investor reached to the point where they got a property under contract and after that they don't have process of disposition of that property and were unable to generate cash flow and sometime loose the deal if it's an assignment contract. </li>
    <li className='li_items'>We have designed a process in which we expidite your real estate investors circle and help to sell your proprty they we helped you acquire the property.</li>
    </ul>,
    "Text Marketing":  <ul><li className='li_items'>The wholesale eco system is holds to major setups Acquistions and Dispostions</li>
    <li className='li_items'>Some investor reached to the point where they got a property under contract and after that they don't have process of disposition of that property and were unable to generate cash flow and sometime loose the deal if it's an assignment contract. </li>
    <li className='li_items'>We have designed a process in which we expidite your real estate investors circle and help to sell your proprty they we helped you acquire the property.</li>
    </ul>,
    "Accounts & Book Keeping":  <ul><li className='li_items'>The wholesale eco system is holds to major setups Acquistions and Dispostions</li>
    <li className='li_items'>Some investor reached to the point where they got a property under contract and after that they don't have process of disposition of that property and were unable to generate cash flow and sometime loose the deal if it's an assignment contract. </li>
    <li className='li_items'>We have designed a process in which we expidite your real estate investors circle and help to sell your proprty they we helped you acquire the property.</li>
    </ul>,
    "Blog and Content Writing":  <ul><li className='li_items'>The wholesale eco system is holds to major setups Acquistions and Dispostions</li>
    <li className='li_items'>Some investor reached to the point where they got a property under contract and after that they don't have process of disposition of that property and were unable to generate cash flow and sometime loose the deal if it's an assignment contract. </li>
    <li className='li_items'>We have designed a process in which we expidite your real estate investors circle and help to sell your proprty they we helped you acquire the property.</li>
    </ul>
  };
  const serviceContentButton = {
    "List Building & Data Management": <Link to='/list-service'> <button className='discover_more'>DISCOVER MORE</button></Link>,
    "Cold Calling": <Link to='/cold-calling'> <button className='discover_more'>DISCOVER MORE</button></Link>,
    "Follow up Manager": <Link to='/followup-manager'> <button className='discover_more'>DISCOVER MORE</button></Link>,
    "Acquisition Manager": <Link to='/acquistion-manager'> <button className='discover_more'>DISCOVER MORE</button></Link>,
    "Dispositions": <Link to='/'> <button className='discover_more'>DISCOVER MORE</button></Link>,
    "Email Marketing": <Link to='/email-marketing'> <button className='discover_more'>DISCOVER MORE</button></Link>,
    "Ringless Voice Mails": <Link to='/ringless-voice'> <button className='discover_more'>DISCOVER MORE</button></Link>,
    "Post Card Campaigns": <Link to='/post-card'> <button className='discover_more'>DISCOVER MORE</button></Link>,
    "Text Marketing": <Link to='/text-marketing'> <button className='discover_more'>DISCOVER MORE</button></Link>,
    "Accounts & Book Keeping": <Link to='/account-book-keeping'> <button className='discover_more'>DISCOVER MORE</button></Link>,
    "Blog and Content Writing":<Link to='/blog-content'> <button className='discover_more'>DISCOVER MORE</button></Link>
  };
  return (
    <div>
      <div className='container pt-5'>
        <h1 className='dedicatedService_head'>Our Dedicated Services</h1>
        <p className='dedicatedService_content'>Intrinsicly transition intermandated content through accurate manufactured <br/>
        products. Uniquely exploit premier best.</p>
      </div>
      <div className="services-container">
        {services.map((service, index) => (
          <div key={index} className={"service" + (selectedServiceIndex === index ? " selected" : "")} onClick={() => handleServiceClick(service)}>
            <img src={serviceImages[service]} alt={service} className="service-image" />
            <p className="service-description">{service}</p>
          </div>
        ))}
      </div>
      <div className='service_data mt-5'>
        {services[selectedServiceIndex] && (
          <div className="container selected-service">
            <div className='row both_content'>
              <div className='col-xl-2 col-lg-4 col-md-5 col-sm-3 col-8'><img src={serviceImages[services[selectedServiceIndex]]} alt="Selected Service Image" className='service_img'/></div>
              <div className='col-xl-6 col-lg- col-md- col-sm- col- mb-5 dis_text'>
                <h2 className='content_name_Slider ml-4'>{services[selectedServiceIndex]}</h2>
                <p className='content_name_Slider_p'>{serviceContent[services[selectedServiceIndex]]}</p>
               
                <button className='discover_more ml-4'>{serviceContentButton[services[selectedServiceIndex]]}</button>
             
              </div> 
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ServiceTest;
